export const getStatus = rawStatus => {
  let status
  switch (rawStatus) {
    case 'success':
      status = 'SUCCEEDED'
      break
    case 'failure':
      status = 'FAILED'
      break
    case 'error':
      status = 'ERROR'
      break
    case 'skipped':
      status = 'SKIPPED'
      break
    default:
      status = 'OTHER'
  }
  return status
}
